<template>
  <div class="relative w-screen h-screen bg-dark flex flex-col items-center overflow-hidden">
    <Logo class="h-20 mt-72 w-20 sm:h-24 sm:w-24" />
    <h1>
      <img src="/Almana.cc.svg" alt="Almana.cc" class="h-10 rotate-[5deg] fill-black sm:h-16 sm:-mt-2">
    </h1>
    <img class="absolute top-[-2%] right-0 h-[25%] rotate-45" src="/blob-yellow.svg" alt="Yellow Blob">
    <img class="absolute bottom-[20%] left-[-2%] h-[25%]" src="/blob-red.svg" alt="Red Blob">
    <img
      class="absolute bottom-[-10%] right-[-27%] h-[28%] sm:right-[-15%] lg:right-[-7%]" src="/blob-blue.svg"
      alt="Blue Blob Da Ba Dee">
      <slot />
  </div>
</template>